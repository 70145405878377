.slick-bg{
    box-shadow: 20px 20px 50px white;
}

.slick-prev:before, .slick-next:before {
    font-family: monospace;
    font-size: 41px;
    line-height: 0;
    opacity: .9;
    display: flex;
    justify-content: center;
    color: #6B6B6B;
}

.slick-prev {
    left: 3px;
}
.slick-next {
    right: 5px;
}